import { makeStyles } from '@material-ui/core';
import React, { ReactNode } from 'react';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  container: {
    paddingBottom: spacing(1),
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
    [breakpoints.down('sm')]: {
      paddingTop: `calc(${spacing(1)}px + 56px)`,
    },
    [breakpoints.up('sm')]: {
      paddingTop: `calc(${spacing(1)}px + 64px)`,
    },
  },
}));

export function Main({ children }: { children: ReactNode | ReactNode[] }) {
  const classes = useStyles();

  return <main className={classes.container}>{children}</main>;
}
