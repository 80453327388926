import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import React from 'react';
import { Main } from './Main';
import { TopAppBar } from './TopAppBar';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#4bb666',
      main: '#02853a',
      dark: '#00570f',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff8a50',
      main: '#ff5722',
      dark: '#c41c00',
      contrastText: '#000',
    },
  },
});

function App() {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <TopAppBar />
        <Main>Hello world</Main>
      </ThemeProvider>
    </>
  );
}

export default App;
